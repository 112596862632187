<template></template>

<script lang="ts">
import {defineComponent, inject, onBeforeUnmount, onMounted, ref, watch} from 'vue';
import {i18n} from "@ds_boilerplate/Vue/util";

const CLASS_MEDIA_OVERLAY = 'cookies-info-box-overlay';

export default defineComponent({
  setup() {
    const currentConsentStatus = ref(false);

    onMounted(() => {
      disableIFrames();
      setInterval(() => {fdConsentStatus();},1000);
    });

    function fdConsentStatus() {
      if (window.fdData) {
        let fdData = window.fdData;

        for (let i = fdData.length - 1; i >= 0; i--) {
          let entry = fdData[i];

          // Check whether an entry has the consentMarketing property
          if (entry.hasOwnProperty('consentMarketing')) {
            if (currentConsentStatus.value !== entry.consentMarketing) {
              if (entry.consentMarketing) {
                enableScripts();
                enableIFrames(); // Must be called before disable IFrames
              } else {
                disableIFrames();
              }
              currentConsentStatus.value = entry.consentMarketing;
            }

            // Stop the search if an entry with consentMarketing is found
            break;
          }
        }
      }
    }

    function cloneScriptTag(script: HTMLScriptElement): HTMLScriptElement {
      const clonedScript: HTMLScriptElement = document.createElement('script');

      for (let i = 0; i < script.attributes.length; i++) {
        clonedScript.setAttribute(script.attributes[i].name, script.attributes[i].value);
      }

      if (script.text) {
        clonedScript.text = script.text;
      }

      clonedScript.setAttribute('type', 'text/javascript');

      return clonedScript;
    }

    async function disableIFrames(scope: ParentNode = document) {
      const openCookiesBtn = document.createElement('button');
      openCookiesBtn.innerHTML = i18n.t('cookies.info.disabled');
      openCookiesBtn.classList.add('btn', `${CLASS_MEDIA_OVERLAY}-btn`);
      const divTag = document.createElement('div');
      divTag.classList.add(CLASS_MEDIA_OVERLAY);
      divTag.appendChild(openCookiesBtn)

      await scope.querySelectorAll('iframe')
        .forEach((iframe) => {
          iframe.parentNode?.insertBefore(divTag.cloneNode(true), iframe);
          iframe.style.display = 'none';
        });

      document.addEventListener('click', (event) => {
        const target = event.target as HTMLElement;

        if (target) {
          const isTargetDivTag = target.classList.contains(`${CLASS_MEDIA_OVERLAY}-btn`);

          if (isTargetDivTag) {
            open();
          }
        }
      });
    }

    function open() {
      if (window.fdConsentManager) {
        window.fdConsentManager.showBanner();
      }
    }

    function enableIFrames(scope: ParentNode = document) {
      scope.querySelectorAll(`.${CLASS_MEDIA_OVERLAY}`)
        .forEach((message) => {
          message.parentNode?.removeChild(message);
        });


      scope.querySelectorAll('iframe')
        .forEach((iframe) => {
          iframe.style.display = 'block';
        });
    }

    function enableScripts(scope: Element | Document = document) {
      for (const script of Array.from(scope.getElementsByTagName('script'))) {
        if (script.type.toLowerCase() === 'text/plain') {
          const parent = script.parentNode;
          const sibling = script.nextElementSibling;
          const clonedScript = cloneScriptTag(script) as HTMLScriptElement;
          const newType = clonedScript.getAttribute('data-type');
          if (newType) {
            clonedScript.setAttribute('type', newType);
            clonedScript.removeAttribute('data-type');
          }

          if (parent) {
            parent.removeChild(script);
            parent.insertBefore(clonedScript, sibling || null);
          }
        }
      }
    }

    return {};
  }
});
</script>
