const PREVENT_UNLOAD_CLASSES = [
  '.ajax',
  '.download',
  '#scroll-to-top',
  '[data-photoswipe]',
  '[download]',
  '[href^=\\#]',
  '[href*=ajax]',
  '[href^=javascript]',
  '[href^=mailto]',
  '[href^=tel]',
  '[href*=tx_typoscriptrendering]',
  '[target^=_blank]',
];

export default function (): void {
  const links = `a:not(${PREVENT_UNLOAD_CLASSES.join(', ')})`;

  window.addEventListener('load', () => {
    document.body.classList.add('loaded');

    document.querySelectorAll<HTMLAnchorElement>(links).forEach((link) => {
      link.addEventListener('click', (event) => {
        const target = event.currentTarget as HTMLAnchorElement | null;

        if (event.ctrlKey || event.shiftKey || event.metaKey || event.button === 1) {
          return true;
        } else if (target?.pathname === window.location.pathname) {
          return true;
        } else if (target?.getAttribute('id') === 'history-back') {
          event.preventDefault();
          if (window.history.length > 1) {
            window.history.back();
          }

          return false;
        }

        document.body.classList.remove('loaded');

        return true;
      });
    });
  });

  // back button fixes
  window.addEventListener('unload', () => {});
  window.onpageshow = (event: PageTransitionEvent) => {
    if (event.persisted) {
      window.location.reload();
    }
  };
}
