<script lang="ts">
import { onMounted, provide, ref } from 'vue';
import AOS from "aos";
import 'dom-slider';
import LazyLoad from "vanilla-lazyload";

const DESKTOP_BREAKPOINT = 991;
const SCROLL_OFFSET = 64;
const SCROLL_DELTA = 50;

export default {
  setup() {
    const header = ref<HTMLElement | null>(null);
    const isMobile = ref(false);
    const lazyLoad = new LazyLoad({
      threshold: 0,
      elements_selector: '[lazy]',
      class_loading: 'lazy-loading',
      class_loaded: 'lazy-loaded',
      class_applied: 'lazy-bg-loaded',
      class_error: 'lazy-error',
    });
    let lastScrollPosition = window.scrollY;
    let isHeaderHidden = ref(false);

    onMounted(() => {
      AOS.init({
        duration: 500,
        once: true,
        disable: ['phone', 'tablet'],
      });

      lazyLoad.update();

      isMobile.value = window.innerWidth <= DESKTOP_BREAKPOINT;
      window.addEventListener('resize', () => {
        isMobile.value = window.innerWidth <= DESKTOP_BREAKPOINT;
      });

      // https://web.dev/lazy-loading-video/
      document.addEventListener("DOMContentLoaded", function() {
        const lazyVideos = [].slice.call(document.querySelectorAll("video.lazy"));

        if ("IntersectionObserver" in window) {
          const lazyVideoObserver = new IntersectionObserver(function (entries) {
            entries.forEach(function (video) {
              if (video.isIntersecting) {
                for (var source in video.target.children) {
                  var videoSource = video.target.children[source];
                  if (typeof videoSource.tagName === "string" && videoSource.tagName === "SOURCE") {
                    videoSource.src = videoSource.dataset.src;
                  }
                }

                video.target.load();
                video.target.setAttribute('autoplay', 'autoplay')
                video.target.classList.remove("lazy");
                lazyVideoObserver.unobserve(video.target);
              }
            });
          });

          lazyVideos.forEach(function(lazyVideo) {
            lazyVideoObserver.observe(lazyVideo);
          });
        }
      });

      window.addEventListener('scroll', () => {
        if (window.scrollY >= 0) {
          if (Math.abs(window.scrollY - lastScrollPosition) >= SCROLL_DELTA) {
            isHeaderHidden.value = window.scrollY >= lastScrollPosition;
            lastScrollPosition = window.scrollY;
          }
        }
      });
    });

    provide('isHeaderHidden', isHeaderHidden);
    provide('isMobile', isMobile);
    provide('lazyLoad', lazyLoad);
    provide('scrollOffset', () => {
      let offset = -SCROLL_OFFSET;

      if (header?.value) {
        if ("offsetHeight" in header.value) {
          offset -= header.value.offsetHeight;
        }
      }

      return offset;
    });

    return {
      header,
      isMobile,
    }
  }
}
</script>
