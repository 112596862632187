import { createI18n } from "vue3-i18n";

const messages = {
  de: {
    cookies: {
      button: {
        acceptAll: 'Accept All',
        confirm: 'Confirm'
      },
      info: {
        disabled: 'Datenschutzeinstellungen ändern um Inhalte anzuzeigen.'
      }
    }
  }
};

export const i18n = createI18n({
  locale: "de",
  messages: messages,
});
